<template>
  <!-- 创建房间 -->
  <div :class="['create_room', { 'animate__animated animate__fadeInDown': $store.state.mode == 'mobile' }]" ref="slider">
    <i class="el-icon-close close_btn" @click="handleClose"></i>
    <h2 class="title pc-show">比赛创建中</h2>
    <div class="total">
      <div>
        回合数：<b>{{ totalround }}</b
        >/12
      </div>
      <div>盲盒价值：<price :size="isPc ? 'large' : 'small'" :price="totalmoney" /></div>
      <div v-if="isPc">
        房间类别 (模式)：
        <p>
          <button :class="{ active: objj.model == 0 }" @click="selectmodel(0)">欧皇</button>
          <button :class="{ active: objj.model == 1 }" @click="selectmodel(1)">非酋</button>
        </p>
      </div>
      <div v-if="isPc">
        对战人数：
        <p>
          <button v-for="item in 2" :key="item" :class="{ active: item + 1 == objj.playerNumber }" @click="selectPeople(item + 1)">{{ item + 1 }}人</button>
        </p>
      </div>
    </div>
    <el-tabs v-model="curTab" @tab-click="handleTab" class="tab_list" id="tabs">
      <el-tab-pane v-for="item in tabs" :key="item.tab" :name="item.tab">
        <span slot="label"><i :class="item.icon"></i> {{ item.name }}</span>
      </el-tab-pane>
    </el-tabs>
    <template v-if="$store.state.mode == 'mobile'">
      <div class="form_wrap" v-if="curTab == 1 || curTab == 2">
        <div class="form_item">
          <el-input v-model="boxPage.minPrice" type="number" placeholder="价格最小值" @change="queryBoxList" />
          <span class="line">-</span>
          <el-input v-model="boxPage.maxPrice" type="number" placeholder="价格最大值" @change="queryBoxList" />
          <button class="range_btn" @click="queryBoxList">筛选</button>
        </div>
      </div>
    </template>
    <div class="form_wrap" v-if="curTab == 1 || curTab == 2">
      <div class="form_item">
        <span class="label">排序：</span>
        <el-select v-model="boxPage.orderByFie" placeholder="请选择" @change="queryBoxList">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="form_item" v-if="isPc">
        <span class="label">
          <price size="large"><span>范围：</span></price>
        </span>
        <el-col :span="11">
          <el-input v-model="boxPage.minPrice" type="number" placeholder="请输入最小值" @change="queryBoxList" />
        </el-col>
        <el-col class="line" :span="1">-</el-col>
        <el-col :span="11">
          <el-input v-model="boxPage.maxPrice" type="number" placeholder="请输入最大值" @change="queryBoxList" />
        </el-col>
      </div>
      <div class="form_item" v-show="curTab == 2">
        <span class="label">玩家ID：</span>
        <el-input :placeholder="`${isPc ? '请输入' : ''}玩家ID`" v-model="boxPage.owner" @change="queryBoxList">
          <i class="el-icon-search mobile-show" slot="suffix" @click="queryBoxList"></i>
        </el-input>
      </div>
      <div class="form_item">
        <span class="label">盲盒ID：</span>
        <el-input :placeholder="`${isPc ? '请输入' : ''}盲盒ID`" v-model="boxPage.boxId" @change="queryBoxList">
          <i class="el-icon-search mobile-show" slot="suffix" @click="queryBoxList"></i>
        </el-input>
      </div>
    </div>
    <div class="call" v-if="curTab == 1">
      <div class="typeBox">
        <div
          class="types"
          @click.stop="
            handleChangeType('', '盲盒类别');
          "
          :class="boxPage.boxTypeId == '' ? `num_select` : `num`"
        >
          全部
        </div>
        <div
          class="types"
          v-for="(item, i) in boxTypeList"
          :class="boxPage.boxTypeId == item.id ? `num_select` : `num`"
          :key="i"
          @click.stop="
            handleChangeType(item.id, item.name);
          "
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="create_box" v-if="boxList.length" @scroll="handleScroll">
      <div class="box_list">
        <div :class="['box_item', { active: item.boxNum > 0 }]" v-for="(item, index) in boxList" :key="'box' + index">
          <div class="box_img" @click="getBoxDetail(item.boxId)">
            <img :src="item.boxImg01" alt width="100%" />
          </div>
          <div class="box_id">ID:{{ item.boxId }}</div>
          <div class="textover-f center_box_name">
            {{ item.boxName }}
          </div>
          <price :price="item.price" size="middle" className="box_money" />
          <div class="box_number">
            <div class="box_input space_between">
              <div @click="handleDel(item, index)" class="jian"><i class="el-icon-remove"></i></div>
              <div class="input_number">{{ item.boxNum }}</div>
              <div @click="handlePush(item)" class="jia"><i class="el-icon-circle-plus"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="boxList.length" style="display: flex;justify-content: center;">
    <el-pagination  background class="pc-show" layout="prev, pager, next" :hide-on-single-page="true" :total="boxTotal" :current-page="boxPage.page" :page-size="boxPage.size" @current-change="queryBoxList" />

    </div>
    <div class="not_data" v-else>--暂无数据--</div>
    <div class="bottom_btn">
      <div class="select_btn" v-if="$store.state.mode == 'mobile'">
        <p>
          <button :class="{ active: objj.model == 0 }" @click="selectmodel(0)">欧皇</button>
          <button :class="{ active: objj.model == 1 }" @click="selectmodel(1)">非酋</button>
        </p>
        <p>
          <button v-for="item in 2" :key="item" :class="{ active: item + 1 == objj.playerNumber }" @click="selectPeople(item + 1)">{{ item + 1 }}人</button>
        </p>
      </div>
      <div class="cerate_btn center" @click="handleCreat">创建新对战+</div>
    </div>
    <boxDetail v-if="showDetail.showDetail" :showDetail="showDetail" :boxId="boxDetailId"></boxDetail>
  </div>
</template>

<script>
import { getUserInfo, createFight, getBoxList, getBoxTypeList } from '@/api/index';
const initForm = {
  page: 1,
  size: 28,
  boxId: '',
  name: '',
  owner: '',
  gameMouldId: 2,
  orderByFie: '5',
  maxPrice: null,
  minPrice: null,
  isPlayerBox: 0,
};
export default {
  data() {
    return {
      showDetail: { showDetail: false },
      boxList: [],
      boxDetailId: null,
      boxTotal: 0,
      changeboxList: [],
      boxTypeList: [],
      money: 0,
      obj: {
        boxData: [],
        createNum: 0,
      },
      objj: {
        model: 0, //模式
        playerNumber: 2, //人数
      },
      boxPage: { ...initForm,boxTypeId:'' },
      typePage: {
        page: 1,
        size: 10,
      },
      isOver: '上拉加载更多',
      curTab: '1',
      tabs: [
        { name: '500的盲盒', icon: 'el-icon-s-home', tab: '1' },
        { name: '玩家的盲盒', icon: 'el-icon-s-platform', tab: '2' },
        { name: '我的盲盒', icon: 'el-icon-s-custom', tab: '3' },
      ],
      options: [
        { value: '5', label: '受欢迎程度' },
        { value: '1', label: '价格（从高到低）' },
        { value: '2', label: '价格（从低到高）' },
      ],
      userId: '',
    };
  },
  created() {
    this.queryUserInfo();
  },
  mounted() {
    this.queryBoxList(1);
    if (this.boxTypeList.length == 0) {
      getBoxTypeList(this.typePage).then(res => {
        if (res?.data?.code != 200) return;
        const list = res?.data?.data?.list || [];
        this.boxTypeList.push(...list);
        if (list.length == this.typePage.size) {
          this.typePage.page++;
          this.getTypeList();
        } else {
          this.getAll = true;
        }
      });
    }
  },
  computed: {
    isPc() {
      return this.$store.state.mode == 'pc';
    },
    totalround() {
      return this.obj.boxData.reduce((total, box) => total + box.boxNum, 0);
    },
    totalmoney() {
      let bean = 0;
      this.obj.boxData.forEach(item => {
        bean += item.price * item.boxNum;
      });
      return bean.toFixed(2);
    },
  },
  methods: {
    handleChangeType(res, name) {
      this.boxPage.boxTypeId = res;
      this.boxPage.boxTypeName = name;
      this.boxPage.page = 1;
      this.isOver = "上拉加载更多";
      
      // this.boxList = this.boxList.filter((item) => {
      //   return item.boxNum != 0;
      // });
      this.queryBoxList();
      // this.istypeshow = false;
    },
    getBoxDetail(id) {
      this.boxDetailId = id;
      this.showDetail.showDetail = true;
    },
    queryUserInfo() {
      this.userId = this.$store.state.USER_INFO.userId;
      if (this.userId) return;
      getUserInfo().then(res => {
        this.userId = res?.data?.data?.userId;
        this.$store.commit('USER_INFO', res?.data?.data || {});
      });
    },
    handleScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.isOver == '上拉加载更多') {
          this.boxPage.page++;
          let timer = setTimeout(() => {
            this.queryBoxList();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    handleTab({ name }) {
      this.playAudio();
      this.curTab = name;
      this.boxPage = { ...initForm };
      if (name == 3) {
        this.boxPage.status = [0];
        delete this.boxPage.gameMouldId;
        delete this.boxPage.isPlayerBox;
      } else {
        this.boxPage.isPlayerBox = name == 1 ? 0 : 1;
        this.boxPage.gameMouldId = 2;
        delete this.boxPage.status;
      }
      this.queryBoxList();
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    //设置房间人数
    selectPeople(res) {
      this.objj.playerNumber = res;
      this.playAudio();
    },
    //设置房间类型
    selectmodel(res) {
      this.playAudio();
      this.objj.model = res;
    },
    // 获取全部的箱子
    queryBoxList(page) {
      this.isOver = '加载中';
      if (page && typeof page == 'number') this.boxPage.page = page;
      const params = { ...this.boxPage };
      if (this.curTab == 3) params.owner = this.userId;
      Object.keys(params).forEach(key => {
        if (params[key] === '' || params[key] === undefined || params[key] === null) delete params[key];
      });
      if (this.curTab == 3) delete params.orderByFie;
      getBoxList(params).then(res => {
        if (res?.data?.code != 200) return;
        const { list, total } = res?.data?.data || {};
        list.map(item => (item.boxNum = 0));
        if (this.isPc) {
          this.boxList = [...list];
          this.boxTotal = total || 0;
        } else {
          if (this.boxPage.page == 1) {
            this.boxList = [...list];
          } else {
            this.boxList.push(...list);
          }
          this.isOver = list?.length < this.boxPage.size ? '暂无更多' : '上拉加载更多';
        }
      });
    },
    /* 关闭 */
    handleClose() {
      this.playAudio();
      const element = this.$refs.slider;
      element.classList += ' animate__fadeOutDown';
      let timer = setTimeout(() => {
        this.boxList = [];
        this.changeboxList = [];
        this.boxTypelist = [];
        this.$router.back();
        this.obj = {
          boxData: [],
          createNum: 0,
          model: 0,
        };
        this.boxTypelist = [];
        clearTimeout(timer);
      }, 350);
    },
    //创建房间
    handleCreat() {
      this.playAudio();
      if (!this.totalround) {
        this.$message({
          type: 'warning',
          message: '请选择对战盲盒',
        });
        return;
      }
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      if (this.objj.boxIdAndNumber) {
        const obj = Array.from(this.objj.boxIdAndNumber).reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {});
        this.objj.boxIdAndNumber = obj;
        // 请求服务器创建对战
        createFight(this.objj).then(res => {
          if (res?.data?.code == 200) {
            // 提示
            this.$message({
              type: 'success',
              message: '创建成功',
            });

            getUserInfo().then(res => {
              if (res?.data?.code == 200) {
                this.userInfo = res?.data?.data || {};
                this.$store.commit('USER_INFO', res?.data?.data);
              } else {
                this.$store.commit('LOGIN_IS_SHOW', true);
              }
            });

            // 跳转
            let timer = setTimeout(() => {
              this.$router.replace({
                name: 'roomDetail',
                query: {
                  id: res?.data?.data.id,
                },
              });
              clearTimeout(timer);
            }, 500);
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
            });
          }
        });
      } else {
        createFight(this.objj).then(res => {
          if (res?.data?.code == 200) {
            this.$message({
              type: 'success',
              message: '创建成功',
            });
            this.$router.push({
              name: 'roomDetail',
              query: {
                id: res?.data?.data.id,
              },
            });
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
            });
          }
        });
      }
    },
    handleDel(res) {
      this.playAudio();
      if (res.boxNum <= 0) {
        return;
      } else {
        if (!this.objj.boxIdAndNumber.set) {
          // console.log('不是map');
          let map = new Map();
          let key;
          for (key in this.objj.boxIdAndNumber) {
            map.set(key, this.objj.boxIdAndNumber[key]);
          }

          this.objj.boxIdAndNumber = map;
        }
        this.objj.boxIdAndNumber.set(res.boxId, res.boxNum - 1);
        // console.log(this.objj.boxIdAndNumber);
        this.$set(res, 'boxNum', res.boxNum - 1);
        // console.log(res);
        // res.boxNum -= 1;
        this.$forceUpdate();
        /* map1.delete(); */
        this.obj.boxData.forEach(item => {
          if (item.boxNum === 0) {
            let index = this.changeboxList.indexOf(item); // 获取元素在数组中的索引位置
            this.obj.boxData.splice(index, 1); // 从数组中移除该元素
          }
        });
      }
    },
    handlePush(res) {
      this.playAudio();
      if (this.obj.boxData.length == 6 && res.boxNum == 0) {
        this.$message({
          type: 'warning',
          message: '最多选择6种盲盒',
        });
        return false;
      }
      if (this.totalround < 12) {
        res.boxNum += 1;
        let index = this.changeboxList.findIndex(item => item.boxId === res.boxId);
        // 如果有就替换，没有就添加
        if (index !== -1) {
          this.changeboxList.splice(index, 1, res);
        } else {
          this.changeboxList.push(res);
        }
        let sum = 0;
        this.changeboxList.forEach(obj => {
          sum += obj.boxNum; // 将每个对象的 value1 值相加到 sum 上
          this.money += obj.price;
        });
        /* console.log(this.changeboxList) */
        const map1 = new Map();
        this.changeboxList.forEach(v => {
          map1.set(v.boxId, v.boxNum);
        });

        this.objj.boxIdAndNumber = map1;
        this.obj.createNum = sum;
        this.obj.boxData = this.changeboxList;
      } else {
        this.$message({
          type: 'warning',
          message: '选择数量超出最大限制',
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.call {
  height: 60px;
  @media @max750 {
    height: 40px;
  }
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  color: white;
  box-sizing: border-box;
  justify-content: center;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0197d4;
    border-radius: 5px;
  }
  .typeBox {
    max-width: 100%;
    // overflow-x: scroll;
    display: flex;
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #0197d4;
      border-radius: 5px;
    }
  }
  .num {
      
      &:hover {
        color: @main;
      }
    }
    .num_select {
      color: @main;
      border-color: @main !important;
    }
  // .num_select {
  //   background-image: url('../../assets/image/num_select.png');
  // }
  // .num {
  //   background-image: url('../../assets/image/num.png');
  // }
  .types {
    // border-radius: 24px;
    min-width: 106px;
    border: 2px solid transparent;
    box-sizing: border-box;
    // display: inline-block;
    font-size: 20px;
    // .sc(24px);
    @media @max750 {
      font-size: 16px;
      min-width: 60px;
    }
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
    background-size: 100% 100% !important;
  }
}
.create_room {
  position: relative;
  margin: 40px @pcSpace 0;
  @media @max750 {
    .fixed_bg;
    margin: 0;
    z-index: 20;
    .flex-column;
    background: #000;
  }
  .close_btn {
    top: 0;
    right: 0;
    padding: 10px;
    color: #fff;
    font-size: 18px;
    position: absolute;
    .mobile-show;
  }
  .title {
    .sc(30px, #fff);
    text-align: center;
  }
  .total {
    .flex-center;
    margin-top: 60px;
    @media @max750 {
      margin-top: 30px;
      justify-content: space-between;
    }
    & > div {
      padding: 0 30px;
      .flex-a-center;
      .sc(18px, #dbdbdb);
      @media @max750 {
        font-size: 14px;
      }
      &:nth-child(2) {
        position: relative;
        &::after {
          content: '';
          .abs-ct;
          .wh(1px, 38px);
          right: 0;
          background: #2d3336;
        }
      }
      b {
        .sc(20px, @main);
        @media @max750 {
          font-size: 14px;
        }
      }
      span {
        padding-left: 4px;
        .sc(16px, #fff);
        @media @max750 {
          font-size: 14px;
        }
      }
      button {
        .btn-style(60px,26px);
        .sc(16px, #fff);
        border: none;
        outline: none;
        border-radius: 20px;
        background: transparent;
      }
      img {
        .wh(16px, 16px);
        margin-right: 4px;
      }
      .active {
        .btn-shadow;
        background: @bg1;
      }
      i {
        color: #ff0000;
      }
    }
  }
}
.form_wrap {
  margin: 20px 0;
  @media @max750 {
    margin: 0 14px 10px;
  }
}
.tab_list {
  width: 100%;
  margin: 20px 0;
  background: @bg1;
  @media @max750 {
    top: 0;
    left: 0;
    z-index: 9;
    margin: 10px 0;
    ::v-deep {
      .el-tabs__item {
        padding-right: 40px;
      }
    }
  }
}
.create_box {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    background: transparent;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    width: 7px;
    background: #323436;
    opacity: 1;
    border-radius: 7px;
  }
  @media @max750 {
    flex: 1;
    padding: 0 14px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
  }
  &.add_box {
    .grid(5, 20px);
    margin: 40px;
    margin-top: 0;
    background: @bg1;
  }
  .active {
    border: 1px solid @main !important;
  }
  .box_list {
    .grid(7, 10px);
    @media @max750 {
      .grid(2, 10px);
    }
  }
  .box_item {
    width: 100%;
    height: 210px;
    margin: 0 auto;
    padding: 20px 0 0px;
    .flex-column-center;
    border: 1px solid #727477;
    border-radius: 14px;
    transition: all 0.3s ease;
    background-color: rgba(114, 116, 119, 0.1);
    position: relative;
  }
  .box_id {
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .box_img {
    margin: 6px 0;
    position: relative;
    .flex-center;
    .bg-shadow(80px);
    img {
      z-index: 1;
      .wh(128px, 95px);
      // @media @max750 {
      //   .wh(90px, 60px);
      // }
    }
  }
  ::v-deep .box_number {
    position: relative;
    width: 160px;
    font-size: 22px;
    margin: 0 10px;
    @media @max750 {
      width: 140px;
    }
    .box_input {
      // background: @main;
      .flex-a-center;
      padding: 6px;
      border-radius: 30px;
      justify-content: space-around;
    }
    .input_number {
      .wh(56px, 32px);
      line-height: 32px;
      background: @bg2;
      text-align: center;
      border-radius: 12px;
      border-radius: 22px;
      .sc(18px, #fff);
      @media @max750 {
        font-size: 16px;
        .wh(54px, 26px);
        line-height: 26px;
        border-radius: 14px;
      }
    }
    .jia,
    .jian {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 50%;
      position: relative;
      i {
        .abs-ctl;
        .sc(36px, @bg2);
      }
      @media @max750 {
        width: 24px;
        height: 24px;
        i {
          .sc(28px, @bg2);
        }
      }
    }
  }
  .box_money {
    margin: 10px 0;
    .flex-center;
    @media @max750 {
      margin: 6px 0;
    }
    img {
      .wh(16px, 16px);
    }
  }
  .center_box_name {
    max-width: 150px;
    font-size: 16px;
    // margin-top: 10px;
  }
}

.bottom_btn {
  padding: 0 14px;
  .select_btn {
    .flex-a-center;
    margin: 10px 0;
    justify-content: space-between;
    button {
      .btn-style(60px, 26px);
      .sc(14px, #fff);
      border-color: transparent;
      outline: none;
      border-radius: 20px;
      background: transparent;
      line-height: 24px;
      &.active {
        .btn-bg;
      }
    }
  }
  .cerate_btn {
    .wh(180px, 44px);
    .btn-shadow;
    .sc(20px, #fff);
    opacity: 1;
    background: @bg1;
    border-radius: 25px;
    text-align: center;
    line-height: 44px;
    margin: 40px auto 4px;
    @media @max750 {
      .wh(30%, 32px);
      font-size: 14px;
      margin: 14px auto;
    }
  }
}
</style>
